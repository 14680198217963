import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import { isLogin } from "../common/userApi";
import Header from "./Header";
import Aside from "./Aside";
import TradeDeals from "./TradeDeals";
import TradeDeviceProfiles from "./TradeDeviceProfiles";
import TradeBankProfiles from "./TradeBankProfiles";
import TradeRequisiteProfiles from "./TradeRequisiteProfiles";
import TradeAppeals from "./TradeAppeals";
import Settings from "./Settings";
import Wallet from "./Wallet";
import { formatFinance } from "../common/utils";

const TraderScreen = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [inactiveTrigger, setInactiveTrigger] = useState(1);

  const [hamburgerActive, setHamburgerActive] = useState(false);

  const incrementInactiveTrigger = () => {
    setInactiveTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    window.addEventListener("userInactive", incrementInactiveTrigger);
    return () => {
      window.removeEventListener("userInactive", incrementInactiveTrigger);
    };
  }, []);

  configure({ axios: $authHost });

  const [{ data, loading }, refetch] = useAxios(
    "/trader/settings",
    axiosConfig
  );

  useEffect(() => {
    setHamburgerActive(false);
    if (isLogin()) {
      refetch().catch((error) => {
        // console.log("refetch", error);
      });
    } else {
      navigate("/");
    }
  }, [params, inactiveTrigger]);

  const isDefault = () => {
    return Object.keys(params).length === 0;
  };

  const isPage = (page) => {
    return Object.keys(params).length >= 1 && params?.slug === page;
  };

  const getSlug = () => {
    return params?.slug ? params?.slug : "deals";
  };

  const getRateStatus = (data) => {
    return data?.available === 1 ? "Включено" : "Выключено";
  };

  return (
    <div className="container">
      <Header
        hamburgerActive={hamburgerActive}
        setHamburgerActive={setHamburgerActive}
        supportTelegramLink={data?.supportTelegramLink}
      />
      <Aside
        hamburgerActive={hamburgerActive}
        user={data?.user}
        slug={getSlug()}
        trade={true}
        supportTelegramLink={data?.supportTelegramLink}
      />
      <div className="main">
        {data?.rateLimits &&
          data?.rateLimits?.length > 0 &&
          data?.rateLimits[0].interest_rate > 0 && (
            <div
              style={{
                marginTop: 10,
                marginBottom: 30,
              }}
            >
              <span
                style={{
                  borderRadius: 20,
                }}
              >
                Процентная ставка для сумм сделок:
                <br />
              </span>
              {data?.rateLimits.map((limit, index) => (
                <span
                  key={limit.id}
                  style={{
                    borderRadius: 20,
                  }}
                >
                  <br /> {"\u25B8"} {limit.interest_rate}% ( от{" "}
                  {formatFinance(limit.min_amount)} до{" "}
                  {formatFinance(limit.max_amount)} ₽ ) {getRateStatus(limit)}
                </span>
              ))}
            </div>
          )}
        {(isPage("deals") || isDefault()) && <TradeDeals />}
        {isPage("appeals") && <TradeAppeals />}
        {isPage("deviceprofiles") && <TradeDeviceProfiles />}
        {isPage("bankprofiles") && <TradeBankProfiles />}
        {isPage("requisiteprofiles") && <TradeRequisiteProfiles />}
        {isPage("wallet") && <Wallet user={data?.user} />}
        {isPage("settings") && <Settings user={data?.user} />}
      </div>
    </div>
  );
};

export default TraderScreen;
