import React, { useState, useEffect } from "react";
import useAxios, { configure } from "axios-hooks";
import { $authHost, axiosConfig } from "../common/api";
import useDayjsFormat from "../hooks/useDayjsFormat";
import { toast } from "react-toastify";
import Spinner from "./spinner/Spinner";
import AnimatedBlock from "./animatedblock/AnimatedBlock";
import Pagination from "./Pagination";
import ModalViewQrWalletTron from "./modal/ModalViewQrWalletTron";
import ModalViewQrWalletTronAddress from "./modal/ModalViewQrWalletTronAddress";
import {
  formatFinance,
  getShortRequisiteData,
  getLkAlias,
} from "../common/utils";

import { AddShopPlusIcon, AcceptIcon, QRCodeIcon } from "./Icons";

const Wallet = (props) => {
  const { user } = props;

  const [modalActive, setModalActive] = useState(false);
  const [modal3Active, setModal3Active] = useState(false);
  const [pageWallet, setPageWallet] = useState(1);
  const count = 10;

  const dealStatus = ["Ожидает", "Завершено"];

  const getDealStatus = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < dealStatus.length) {
      return dealStatus[index];
    } else {
      return "В архиве";
    }
  };

  const dealStyles = [
    "btn-innactive btn-xs btn-warning",
    "btn-innactive btn-xs btn-innactive_success",
  ];
  const getDealStyle = (status) => {
    const index = parseInt(status);
    if (index >= 0 && index < dealStyles.length) {
      return dealStyles[index];
    } else {
      return "btn-innactive btn-xs btn-innactive_draft";
    }
  };

  const { getFullDateMsk, getFullTimeMsk } = useDayjsFormat();
  configure({ axios: $authHost });

  const [{ data, loading }, executePost, cancelRequest] = useAxios(
    {
      url: "/trader/deposit/list",
      method: "POST",
    },
    axiosConfig
  );

  const handleModalActivity = (active = true) => {
    setModalActive(active);
  };
  const handleModal3Activity = (active = true) => {
    setModal3Active(active);
  };

  useEffect(() => {
    if (!modalActive) {
      executePost({
        data: {
          page: pageWallet,
          count: count,
        },
      }).catch((error) => {
        // console.log("executePost", error);
      });
    }

    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, [pageWallet, count, modalActive, modal3Active]);

  let pageCountQuery = Math.ceil(data?.countWallet / count);

  if (loading)
    return (
      <div style={{ display: "flex" }}>
        <Spinner />
      </div>
    );
  return (
    <>
      <ModalViewQrWalletTron
        active={modalActive}
        setActive={handleModalActivity}
      />
      <ModalViewQrWalletTronAddress
        active={modal3Active}
        setActive={handleModal3Activity}
      />

      <div className="main__head">
        <div
          className="text text_xl"
          style={{ textShadow: "1px 3px 1px black" }}
        >
          Депозиты
        </div>

        <button
          className="btn btn-xs btn_success"
          onClick={() => {
            handleModalActivity();
          }}
        >
          Пополнить
        </button>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          alignContent: "center",
        }}
      >
        {
          <AnimatedBlock>
            <table className="fixedtable">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Сумма, usdt</th>
                  <th>Сумма, руб</th>
                  <th>Курс</th>
                  <th>Дата</th>
                  <th>Истекает</th>
                  <th>Получено</th>
                  <th>Статус</th>
                  <th>Адрес</th>
                </tr>
              </thead>
              <tbody>
                {data?.deposits.map(
                  (
                    {
                      id,
                      in_amount,
                      out_amount,
                      coin_rate,
                      status,
                      receivedAt,
                      expired,
                      createdAt,
                    },
                    index
                  ) => (
                    <tr key={id}>
                      <td data-label="ID">{id}</td>
                      <td data-label="Сумма, usdt">
                        {formatFinance(in_amount)} USDT
                      </td>
                      <td data-label="Сумма, руб">
                        {formatFinance(out_amount)} ₽
                      </td>
                      <td data-label="Курс">{formatFinance(coin_rate)} ₽</td>
                      <td data-label="Дата">
                        {getFullTimeMsk(createdAt)}
                        <br />
                        {getFullDateMsk(createdAt)}
                      </td>
                      <td data-label="Истекает">
                        {getFullTimeMsk(expired)}
                        <br />
                        {getFullDateMsk(expired)}
                      </td>
                      <td data-label="Получено">
                        {receivedAt && (
                          <>
                            {getFullTimeMsk(receivedAt)}
                            <br />
                            {getFullDateMsk(receivedAt)}
                          </>
                        )}
                      </td>
                      <td data-label="Статус">
                        {" "}
                        <span
                          className={getDealStyle(status)}
                          onClick={() => {
                            if (status === 0) {
                              //handleChangeDealStatus(id, "close");
                            }
                          }}
                        >
                          {getDealStatus(status)}
                        </span>
                      </td>
                      <td data-label="Адрес">
                        {status === 0 && (
                          <button
                            className="btn btn-xs btn_minimal btn-innactive_success"
                            onClick={() => {
                              handleModal3Activity();
                            }}
                          >
                            <QRCodeIcon />
                          </button>
                        )}
                      </td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </AnimatedBlock>
        }
      </div>
      {
        <Pagination
          page={pageWallet}
          setPage={setPageWallet}
          pageCountQuery={pageCountQuery}
        />
      }
    </>
  );

  /*
  return (
    <div>
      <div className="text text_lg">Кошелек</div>
      <div className="section-about">
        {
          // Добавить варианты вывода
        }
      </div>
    </div>
  );
  */
};

export default Wallet;
