import React from "react";
import { useNavigate } from "react-router-dom";
import { API_DOCS_URL } from "../common/constants";
import { formatFinance } from "../common/utils";
import { setLogout } from "../common/userApi";
import useDayjsFormat from "../hooks/useDayjsFormat";
import {
  ApiIcon,
  ExitIcon,
  SettingsIcon,
  SupportIcon,
  AppealsIcon,
  RequisitesIcon,
  TradedealsIcon,
} from "./Icons";

const Aside = ({ hamburgerActive, user, slug, trade, supportTelegramLink }) => {
  const navigate = useNavigate();

  const { getFullDateMsk } = useDayjsFormat();

  const handleTradedeals = () => {
    navigate("/dashboard/deals");
  };

  /*
  const handleRequisites = () => {
    navigate("/dashboard/requisites");
  };
  */

  const handleAppels = () => {
    navigate("/dashboard/appeals");
  };

  const handleDeviceProfiles = () => {
    navigate("/dashboard/deviceprofiles");
  };

  const handleBankProfiles = () => {
    navigate("/dashboard/bankprofiles");
  };

  const handleRequisiteProfiles = () => {
    navigate("/dashboard/requisiteprofiles");
  };

  const handleWallet = () => {
    navigate("/dashboard/wallet");
  };

  const handleSettings = () => {
    navigate("/dashboard/settings");
  };

  const handleApi = () => {
    window.open(API_DOCS_URL, "_blank");
  };

  const handleSupport = () => {
    // or SUPPORT_TELEGRAM_URL
    window.open(supportTelegramLink, "_blank");
  };

  const handleLogOut = () => {
    setLogout();
    navigate("/");
  };

  const highlightSlug = (_slug) => {
    return _slug === slug ? " menu__item_active" : "";
  };

  return (
    <aside className={hamburgerActive ? "shown" : ""}>
      <nav className="nav">
        <div className="text-muted text-muted_lg">Добро пожаловать,</div>
        <div className="text text_lg">{user?.login || " "}</div>
        <div className="devider"></div>
        <div className="text-muted">ID профиля</div>
        <div className="text">{user?.id || " "}</div>
        <div className="devider"></div>
        {/*
            <div className="text-muted">Регистрация</div>
            <div className="text">{getFullDateMsk(user?.createdAt)}</div>
            <div className="devider"></div>
          */}
        <div className="text-muted">Кошелек</div>
        <div className="text">
          {formatFinance(user?.financial_balance) || " "} ₽
        </div>
        <div className="devider"></div>
        <ul className="menu" style={{ margin: "18px 0" }}>
          {trade && (
            // appeals
            // deals
            // requisites
            <>
              <li
                className={"menu__item" + highlightSlug("deals")}
                onClick={handleTradedeals}
              >
                <TradedealsIcon />
                <span>Сделки</span>
              </li>
              <li
                className={"menu__item" + highlightSlug("appeals")}
                onClick={handleAppels}
              >
                <AppealsIcon />
                <span>Апелляции</span>
              </li>
              <li
                className={"menu__item" + highlightSlug("deviceprofiles")}
                onClick={handleDeviceProfiles}
              >
                <RequisitesIcon />
                <span>Устройства</span>
              </li>
              <li
                className={"menu__item" + highlightSlug("bankprofiles")}
                onClick={handleBankProfiles}
              >
                <RequisitesIcon />
                <span>Банки</span>
              </li>
              <li
                className={"menu__item" + highlightSlug("requisiteprofiles")}
                onClick={handleRequisiteProfiles}
              >
                <RequisitesIcon />
                <span>Реквизиты</span>
              </li>
            </>
          )}
          {user?.wallet_tron && user?.wallet_tron.length > 10 ? (
            <li
              className={"menu__item" + highlightSlug("wallet")}
              onClick={handleWallet}
            >
              <RequisitesIcon />
              <span>Депозиты</span>
            </li>
          ) : null}
          <li
            className={"menu__item" + highlightSlug("settings")}
            onClick={handleSettings}
          >
            <SettingsIcon />
            <span>Настройки</span>
          </li>
          {trade ? null : (
            <>
              <li
                className={"menu__item" + highlightSlug("api")}
                onClick={handleApi}
              >
                <ApiIcon />
                <span>API</span>
              </li>
              <li
                className={"menu__item" + highlightSlug("support")}
                onClick={handleSupport}
              >
                <SupportIcon />
                <span>Поддержка</span>
              </li>
            </>
          )}

          <li className="menu__item menu__item_danger" onClick={handleLogOut}>
            <ExitIcon />
            <span>Выйти</span>
          </li>
        </ul>
      </nav>
    </aside>
  );
};

export default Aside;
