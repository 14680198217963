import React, { useState } from "react";
import useGlobalScrollLock from "../../hooks/useGlobalScrollLock";
import QRCode from "react-qr-code";
import useAxios, { configure } from "axios-hooks";
import { toast } from "react-toastify";
import { useMask, format } from "@react-input/mask";
import { $authHost, axiosConfig } from "../../common/api";
const apkVersion = "0.3.19";

const ModalViewQrWalletTron = ({ active, setActive }) => {
  const [amount, setAmount] = useState("");
  useGlobalScrollLock(active);

  configure({ axios: $authHost });

  const [{ loading }, executePost] = useAxios(
    {
      url: "/trader/deposit/create",
      method: "POST",
    },
    axiosConfig
  );

  const submitModal = async () => {
    await executePost({
      data: {
        amount: amount,
      },
    })
      .then((data) => {
        setActive(false);
        toast.success("Заявка на попоплнение успешно создана");
      })
      .catch((error) => {
        toast.error(error?.response?.data?.error);
      });
  };

  return (
    <div
      className={active ? "modal shown" : "modal"}
      id="modal26"
      onClick={() => setActive(false)}
    >
      {" "}
      <div
        className="dialog"
        onClick={(e) => e.stopPropagation(e)}
        style={
          {
            //backgroundColor: "#fff",
          }
        }
      >
        <div className="dialog__head">
          <div
            className="dialog__head-title"
            style={
              {
                //color: "#000",
              }
            }
          >
            Пополнить баланс
          </div>
          <div
            className="dialog__head-close"
            id="modal16Close"
            onClick={() => setActive(false)}
          >
            <svg
              width="27"
              height="16"
              viewBox="0 0 27 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.630296 7.49551C0.239771 7.88604 0.239771 8.5192 0.630296 8.90973L6.99426 15.2737C7.38478 15.6642 8.01795 15.6642 8.40847 15.2737C8.79899 14.8832 8.79899 14.25 8.40847 13.8595L2.75162 8.20262L8.40847 2.54577C8.79899 2.15524 8.79899 1.52208 8.40847 1.13155C8.01795 0.741029 7.38478 0.741029 6.99426 1.13155L0.630296 7.49551ZM26.3374 7.20262H1.3374V9.20262H26.3374V7.20262Z"
                fill="white"
              />
            </svg>
          </div>
        </div>

        <div
          className="dialog__body"
          style={{
            textAlign: "left",
            overflow: "auto",
            maxHeight: "calc(100vh - 230px)",
          }}
        >
          <div className="custom-input custom-input_block">
            <label htmlFor="deviceprofile-phone">
              Точная сумма пополнения, USDT (Tron, TRC-20)
            </label>
            <input
              value={amount}
              type="text"
              id="deviceprofile-phone"
              autoComplete="off"
              placeholder="1000"
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>

          <div className="mt-50">
            <button
              disabled={loading}
              className="btn btn-success btn_sm"
              onClick={submitModal}
            >
              Добавить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalViewQrWalletTron;
